import React, { useState, useEffect } from "react";
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButton,
  IonButtons,
  IonTextarea,
  IonLabel,
  IonInput,
  IonText,
  IonSegmentButton,
  IonSegment,
} from "@ionic/react";
import { Clipboard } from '@capacitor/clipboard';
import {
  formatTime
} from "../../utilities/utilities"

import { isPlatform } from '@ionic/react';

// iOS has some strange color behavior, so need to detect and adjust colors
const ios = isPlatform('ios');

// This interface defines the shape of one sectionLink object.
// interface SectionLink {
//   s: string;        // start time
//   e: string;        // end time
//   t: string;        // text
//   name: string;     // up to 24 chars, no spaces
//   linkList?: string[]; // array of link names (e.g. ["main", "endingA"])
// }
interface SectionLink {
  mode: "chapter" | "link";
  //chapter mode
  s?: string;        // start time
  e?: string;        // end time

  // For both modes:
  t: string;        // text
  name: string;     // e.g. "Main3"

  linkList?: string[]; // Initially just a single value
  orderNumber?: number;

  resultMessage?: string; //optional for link type only
}

interface SectionLinkEditorProps {
  sectionLink: SectionLink;
  isOpen: boolean;
  onClose: () => void;
  onSave: (editedLink: SectionLink[]) => void;
  existingLinks: SectionLink[];
}

const SectionLinkEditor: React.FC<SectionLinkEditorProps> = ({
  sectionLink,
  isOpen,
  onClose,
  onSave,
  existingLinks,
}) => {
  // local state
  const [editedLink, setEditedLink] = useState<SectionLink>(sectionLink);
  const [copyButtonLabel, setCopyButtonLabel] = useState('Copy to Clipboard');
  const [linkListInput, setLinkListInput] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

// // A simple time overlap helper
// function timesOverlap(s1: string, e1: string, s2: string, e2: string): boolean {
//   const start1 = convertToSeconds(s1);
//   const end1   = convertToSeconds(e1);
//   const start2 = convertToSeconds(s2);
//   const end2   = convertToSeconds(e2);

//   // Overlap if start1 < end2 AND end1 > start2
//   return start1 < end2 && end1 > start2;
// }
function timesOverlap(s1?: string, e1?: string, s2?: string, e2?: string): boolean {
  const start1 = convertToSeconds(s1 ?? "");
  const end1   = convertToSeconds(e1 ?? "");
  const start2 = convertToSeconds(s2 ?? "");
  const end2   = convertToSeconds(e2 ?? "");

  return start1 < end2 && end1 > start2;
}

// Convert "HH:MM:SS" to total seconds
function convertToSeconds(hms: string): number {
  const parts = hms.split(":").map(Number);
  if (parts.length === 3) {
    return parts[0] * 3600 + parts[1] * 60 + parts[2];
  } else if (parts.length === 2) {
    return parts[0] * 60 + parts[1];
  }
  // Fallback if user enters only "SS"
  return parts[0] || 0;
}


  useEffect(() => {
    // whenever the passed-in `sectionLink` changes, reset our local state
    setEditedLink(sectionLink);
    if (sectionLink.linkList) {
      setLinkListInput(sectionLink.linkList.join(", "));
    }
    setEditedLink(sectionLink);
    setErrorMsg(""); // reset error on open
  }, [sectionLink]);


  // Do all the validation here and pass to parent so they can just save to storage -------
  const handleSave = (e: React.MouseEvent<HTMLIonButtonElement>) => {
    e.stopPropagation();

    // 1) Remove all spaces from `name`
    const nameNoSpaces = editedLink.name.replace(/\s+/g, '');
    if (!nameNoSpaces) {
      alert("Name cannot be blank. Please enter a name without spaces.");
      return;
    }

    // 2) Must not exceed 24 characters
    if (nameNoSpaces.length > 24) {
      alert("Name must not exceed 24 characters (no spaces).");
      return;
    }

    // // Overlap check
    // for (const l of existingLinks) {
    //   // If we're editing an existing link, skip it if it's the same link
    //   if (l.name === sectionLink?.name) continue;

    //   if (timesOverlap(editedLink.s, editedLink.e, l.s, l.e)) {
    //     // show error, do NOT close modal
    //     setErrorMsg(
    //       `Error: times overlap with existing link "${l.name}".`
    //     );
    //     return; // Stop right here
    //   }
    // }
    // 2) Check mode constraints
    if (editedLink.mode === "chapter") {
      // Must have valid s/e
      if (!editedLink.s || !editedLink.e) {
        setErrorMsg("Chapter mode requires valid start/end times.");
        return;
      }
      // Overlap check only if mode=chapter
      for (const l of existingLinks) {
        if (l.mode === "chapter" && l.name !== sectionLink.name) {
          // if the existing link is also a chapter
          if (timesOverlap(editedLink.s, editedLink.e, l.s, l.e)) {
            setErrorMsg(`Time overlap with "${l.name}"`);
            return;
          }
        }
      }
      // linkList/orderNumber not relevant => reset them if you want
      if (editedLink.linkList) editedLink.linkList = [];
      if (editedLink.orderNumber != null) {
        editedLink.orderNumber = undefined;
      }
    } else {
      // link mode => s/e not used
      editedLink.s = "";
      editedLink.e = "";

      // Must have orderNumber
      if (editedLink.orderNumber == null) {
        setErrorMsg("Link mode requires an orderNumber.");
        return;
      }
      // Check uniqueness among link items
      const conflict = existingLinks.find(
        (l) =>
          l.mode === "link" &&
          l.orderNumber === editedLink.orderNumber &&
          l.name !== sectionLink.name
      );
      if (conflict) {
        setErrorMsg(`OrderNumber ${editedLink.orderNumber} already in use by "${conflict.name}"`);
        return;
      }
      // (resultMessage is optional, but we could trim it if needed)
      if (editedLink.resultMessage && editedLink.resultMessage.length > 256) {
        setErrorMsg("Result message cannot exceed 256 characters.");
        return;
      }
    }


    // // Merge final changes
    // const finalLink = { ...editedLink, name: nameNoSpaces };

    // 3) Merge final changes (use the new name minus spaces)
    const finalLink: SectionLink = { ...editedLink, name: nameNoSpaces };
    if (finalLink.mode === "chapter") {
      // Force them to HH:MM:SS
      finalLink.s = formatTime(finalLink.s ?? "");
      finalLink.e = formatTime(finalLink.e ?? "");
    }

    // 4) Build the new array: Insert or replace finalLink into existingLinks
    const copy = [...existingLinks];
    // find old link
    const oldIndex = copy.findIndex((x) => x.name === sectionLink.name);

    if (oldIndex >= 0) {
      copy[oldIndex] = finalLink;
    } else {
      copy.push(finalLink);
    }

    // 5) Sort the entire array so chapters come first (by start time),
    // then link items (by orderNumber).
    const chapters = copy.filter((x) => x.mode === "chapter");
    // Sort chapters ascending by convertToSeconds(x.s)
    chapters.sort((a, b) => convertToSeconds(a.s ?? "0") - convertToSeconds(b.s ?? "0"));

    const links = copy.filter((x) => x.mode === "link");
    // Sort link items ascending by (x.orderNumber ?? 99999)
    links.sort((a, b) => (a.orderNumber ?? 0) - (b.orderNumber ?? 0));

    const finalSortedArray = [...chapters, ...links];

    // 6) Pass final array back up, close
    onSave(finalSortedArray);

    // pass finalLink back to parent
    // onSave(finalLink);
    onClose();
  };


  // Optional: copy the `t` field text to clipboard
  const copyToClipboard = async () => {
    await Clipboard.write({
      string: editedLink.t,
    });
    setCopyButtonLabel('Copied!');
    setTimeout(() => {
      setCopyButtonLabel('Copy to Clipboard');
    }, 1500);
  };

  return (
    <IonModal
      isOpen={isOpen}
      onIonModalDidDismiss={onClose}
      // You can set a custom ID for styling
      id="sectionLink-modal"
    >
      <IonHeader>
        <IonToolbar>
          <IonTitle className="ion-text-center">Edit SectionLink</IonTitle>

          <IonButtons slot="start">
            <IonButton size="small" fill="outline" onClick={onClose}>
              Cancel
            </IonButton>
          </IonButtons>

          <IonButtons slot="end">
            <IonButton color="primary" size="small" fill="outline" onClick={handleSave}>
              Save
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonSegment
  value={editedLink.mode}
  onIonChange={(e) => {
    // When user toggles segment, update editedLink.mode
    const newMode = e.detail.value as "chapter" | "link";
    setEditedLink((prev) => ({ ...prev, mode: newMode }));
  }}

  style={
    ios
      ? {
          '--background': '#f4f5f8',
          '--color': '#3880ff',
          '--color-checked': '#3880ff',
        }
      : {}
  }
>
  <IonSegmentButton value="chapter">
    <IonLabel>Chapter</IonLabel>
  </IonSegmentButton>
  <IonSegmentButton value="link">
    <IonLabel>Link</IonLabel>
  </IonSegmentButton>
</IonSegment>

      <IonContent  class="ion-padding" >

                {/* Display any error at the top */}
                {errorMsg && (
          <IonText color="danger" style={{ display: "block", marginBottom: "1rem" }}>
            {errorMsg}
          </IonText>
        )}

{editedLink.mode === "chapter" && (
  <>
    {/* Start Time */}
    <IonLabel style={{ display: "block", marginTop: "8px" }}>Start Time</IonLabel>
    <IonInput
      value={editedLink.s}
      onIonChange={(e) => {
        if (e.detail.value !== null) {
          setEditedLink({ ...editedLink, s: e.detail.value ?? "" });
        }
      }}
      style={{ background:"rgb(51,51,51)", marginBottom: "12px" }}
    />

    {/* End Time */}
    <IonLabel style={{ display: "block", marginTop: "8px" }}>End Time</IonLabel>
    <IonInput
      value={editedLink.e}
      onIonChange={(e) => {
        if (e.detail.value !== null) {
          setEditedLink({ ...editedLink, e: e.detail.value ?? "" });
        }
      }}
      style={{ background:"rgb(51,51,51)", marginBottom: "15px" }}
    />
  </>
)}

{editedLink.mode === "link" && (
  <>
    {/* orderNumber */}
    <IonLabel>Order Number (display order)</IonLabel>
    <IonInput
      type="number"
      value={editedLink.orderNumber}
      onIonChange={(e) => {
        const val = e.detail.value ? parseInt(e.detail.value, 10) : undefined;
        setEditedLink({ ...editedLink, orderNumber: val });
      }}
      style={{ background:"rgb(51,51,51)", marginBottom: "12px" }}
    />

    {/* linkList */}
    <IonLabel>linkList (group to load when clicked)</IonLabel>
    <IonInput
      value={linkListInput}
      placeholder="ex: main, alt_ending, bonus"
      onIonChange={(e) => {
        const val = e.detail.value ?? "";
        setLinkListInput(val);
        const newList = val.split(",").map((s) => s.trim()).filter(Boolean);
        setEditedLink({ ...editedLink, linkList: newList });
      }}
      style={{ background:"rgb(51,51,51)", marginBottom: "12px" }}
    />

<IonLabel>Result Message (max 256 characters)</IonLabel>
            <IonTextarea
              rows={2}
              placeholder="Enter the message to display after this tile is clicked (ex. Incorrect, try again!)"
              value={editedLink.resultMessage}
              onIonChange={(e) => {
                setEditedLink({ ...editedLink, resultMessage: e.detail.value ?? "" });
              }}
              style={{ background:"rgb(51,51,51)", marginBottom: "15px" }}
            />


  </>
)}


{/* (D) Common fields for both modes */}
<IonLabel>Name (auto generated, no spaces, max 24 chars)</IonLabel>
  <IonInput
    value={editedLink.name}
    placeholder="(ex: main2)"
    onIonChange={(e) => {
      if (e.detail.value !== null) {
        setEditedLink({ ...editedLink, name: e.detail.value ?? ""});
      }
    }}
    style={{ background:"rgb(51,51,51)", marginBottom: "12px" }}
  />

  <IonLabel>Text</IonLabel>
  <IonTextarea
    rows={4}
    placeholder="Enter text the user will see on the tile,  (ex. Answer C)"
    value={editedLink.t}
    onIonChange={(e) => {
      if (e.detail.value) {
        setEditedLink({ ...editedLink, t: e.detail.value });
      }
    }}
    style={{ background:"rgb(51,51,51)" }}
/>


        <div style={{ marginTop: "16px", display: "flex", justifyContent: "end" }}>
          {/* <IonButton fill="outline" size="small" onClick={copyToClipboard}>
            {copyButtonLabel}
          </IonButton> */}
          <IonButton size="small" color="medium" onClick={onClose}>
            Cancel
          </IonButton>
          <IonButton size="small" onClick={handleSave}>
            Save
          </IonButton>
        </div>

      </IonContent>
    </IonModal>
  );
};

export default SectionLinkEditor;

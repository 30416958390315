import { alertController } from '@ionic/core';
import { Storage } from '@ionic/storage';

const storage = new Storage();
storage.create();

/**
 * Automatically stores new group data under a parent key ensuring a unique group key.
 * If newGroupKey already exists, a numeric suffix is appended until a unique key is found.
 *
 * @param parentKey - The storage key under which the parent object is saved (e.g. "c_subtitles_VIDEOID")
 * @param newGroupKey - The proposed key for the new group (e.g. "custom1") <-- makes sure this is unique ***
 * @param newGroupData - The data to store for this group
 * @returns A Promise resolving to the unique key under which the new group data was stored.
 */
export const storeUniqueGroup = async (
  parentKey: string,
  newGroupKey: string,
  newGroupData: any
): Promise<string> => {
  const storedData = await storage.get(parentKey);
  let parentData: Record<string, any> = storedData ? JSON.parse(storedData) : {};

  let uniqueKey = newGroupKey;
  let counter = 1;
  while (parentData.hasOwnProperty(uniqueKey)) {
    uniqueKey = `${newGroupKey}_${counter}`;
    counter++;
  }

  parentData[uniqueKey] = newGroupData;
  await storage.set(parentKey, JSON.stringify(parentData));
  return uniqueKey;
};

/**
 * Stores new group data under a parent key, prompting the user if the proposed group key already exists.
 *
 * If the key exists, an alert is shown asking the user whether to:
 * - Overwrite the existing group (update that key),
 * - Keep both (store the new data under a unique key by appending a numeric suffix), or
 * - Cancel the operation.
 *
 * @param parentKey - The storage key under which the parent object is saved (e.g. "c_subtitles_VIDEOID")
 * @param newGroupKey - The proposed key for the new group (e.g. "custom1")
 * @param newGroupData - The data to store for this group
 * @returns A Promise resolving to the key under which the new group data was stored.
 *          If the user cancels, the Promise is rejected.
 */
export const storeGroupWithPrompt = async (
  parentKey: string,
  newGroupKey: string,
  newGroupData: any,
  titleText: string = ""
): Promise<string> => {
  const storedData = await storage.get(parentKey);
  let parentData: Record<string, any> = storedData ? JSON.parse(storedData) : {};

  const headerValue = titleText && titleText.trim() !== "" 
  ? `${titleText}`
  : "Duplicate name found";

  if (parentData.hasOwnProperty(newGroupKey)) {
    // Prompt the user if the group key already exists.
    const alert = await alertController.create({
      header: headerValue,
      message: `A group with the name "${newGroupKey}" already exists. Do you want to overwrite it, keep both, or cancel?`,
      buttons: [
        {
          text: 'Overwrite',
          role: 'overwrite'
        },
        {
          text: 'Keep Both',
          role: 'keep'
        },
        {
          text: 'Cancel',
          role: 'cancel'
        }
      ]
    });
    await alert.present();
    const { role } = await alert.onDidDismiss();

    if (role === 'overwrite') {
      // Overwrite the existing group.
      parentData[newGroupKey] = newGroupData;
      await storage.set(parentKey, JSON.stringify(parentData));
      return newGroupKey;
    } else if (role === 'keep') {
      // Generate a unique key as in storeUniqueGroup.
      let uniqueKey = newGroupKey;
      let counter = 1;
      while (parentData.hasOwnProperty(uniqueKey)) {
        uniqueKey = `${newGroupKey}_${counter}`;
        counter++;
      }
      parentData[uniqueKey] = newGroupData;
      await storage.set(parentKey, JSON.stringify(parentData));
      return uniqueKey;
    } else {
      // User cancelled the operation.
      throw new Error('User cancelled saving the group.');
    }
  } else {
    // No duplicate exists; simply store the new group data.
    parentData[newGroupKey] = newGroupData;
    await storage.set(parentKey, JSON.stringify(parentData));
    return newGroupKey;
  }
};

import React, { useState } from 'react';
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonCheckbox,
  IonButton,
  IonFooter,
  IonButtons,
  IonIcon,
} from '@ionic/react';
import { GenShareVideo } from '../../utils/apiYT';
import {
  createOutline,
  settingsOutline,
  trashOutline,
  informationCircleOutline,
  gridOutline,
  copyOutline,
  repeatOutline,
  constructOutline,
  construct,
  hammerOutline,
  refreshOutline,
  globeOutline,
  shareSocialSharp,
  shareSocial,
} from "ionicons/icons"


// Type definitions for custom subtitles and chapter groups:
export interface Subtitle {
  i: number;
  s: string;
  e: string;
  t: string;
}

export interface SectionLink {
  mode: 'chapter' | 'link';
  s?: string;
  e?: string;
  t: string;
  name: string;
  linkList?: string[];
  orderNumber?: number;
  resultMessage?: string;
}

interface ShareCodeModalProps {
  isOpen: boolean;
  onDidDismiss: () => void;
  videoId: string;
  customSubtitles: Record<string, Subtitle[]>;
  chapterGroups: Record<string, SectionLink[]>;
  onCopyShareCode: (text: string, event: React.MouseEvent) => Promise<void>;

}

const ShareCodeModal: React.FC<ShareCodeModalProps> = ({
  isOpen,
  onDidDismiss,
  videoId,
  customSubtitles,
  chapterGroups,
  onCopyShareCode

}) => {
  // State: maintain a set of selected keys for each group
  const [selectedSubtitleKeys, setSelectedSubtitleKeys] = useState<Set<string>>(new Set());
  const [selectedChapterKeys, setSelectedChapterKeys] = useState<Set<string>>(new Set());
  const [shareCode, setShareCode] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubtitleChange = (groupKey: string, detail: { checked: boolean }) => {
    setSelectedSubtitleKeys(prev => {
      const newSet = new Set(prev);
      if (detail.checked) {
        newSet.add(groupKey);
      } else {
        newSet.delete(groupKey);
      }
      return newSet;
    });
  };
  
  const handleChapterChange = (groupKey: string, detail: { checked: boolean }) => {
    setSelectedChapterKeys(prev => {
      const newSet = new Set(prev);
      if (detail.checked) {
        newSet.add(groupKey);
      } else {
        newSet.delete(groupKey);
      }
      return newSet;
    });
  };

  // Toggle selection for a custom subtitles group (by its key)
  const toggleSubtitleKey = (groupKey: string) => {
    setSelectedSubtitleKeys(prev => {
      const newSet = new Set(prev);
      if (newSet.has(groupKey)) {
        newSet.delete(groupKey);
      } else {
        newSet.add(groupKey);
      }
      return newSet;
    });
  };

  // Toggle selection for a chapter groups group (by its key)
  const toggleChapterKey = (groupKey: string) => {
    setSelectedChapterKeys(prev => {
      const newSet = new Set(prev);
      if (newSet.has(groupKey)) {
        newSet.delete(groupKey);
      } else {
        newSet.add(groupKey);
      }
      return newSet;
    });
  };

  const handleGenerateCode = async () => {
    setLoading(true);
console.log("Passed into Share Code modal:", customSubtitles)
    // Build objects for selected groups:
    const selectedSubsObj: Record<string, Subtitle[]> = {};
    selectedSubtitleKeys.forEach(key => {
      selectedSubsObj[key] = customSubtitles[key];
    });

    const selectedChapsObj: Record<string, SectionLink[]> = {};
    selectedChapterKeys.forEach(key => {
      selectedChapsObj[key] = chapterGroups[key];
    });

    try {
      const result = await GenShareVideo(videoId, selectedSubsObj, selectedChapsObj);
      if (result && result.share_code) {
        setShareCode(result.share_code);
      }
    } catch (err) {
      console.error('Error generating share code:', err);
    }
    setLoading(false);
  };

  return (
    <IonModal isOpen={isOpen} onDidDismiss={onDidDismiss} backdropDismiss={true}>
      <IonHeader >
        <IonToolbar>
          <IonTitle>Share Video</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onDidDismiss}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent class="ion-padding">
      <IonLabel>Share this video by generating a share code that is valid for 24 hours.
      Optionally include any of your custom subtitles and chapter groups</IonLabel>
      <IonItem button detail={false} onClick={(e) => onCopyShareCode(`https://youtu.be/${videoId}`, e)}
        style={{ 
          marginBottom: '2px', 
          // backgroundColor: 'grey', 
          borderRadius: '4px', 
          padding: '8px', 
          wordBreak: 'break-all' 
        }}>
        <IonLabel>
          {`https://youtu.be/${videoId}`}
        </IonLabel>
        <IonIcon size="small" icon={copyOutline} slot="end" />
      </IonItem>

        <h3>Your Custom Subtitles <span style={{fontSize:"1rem"}}>(select to share)</span></h3>
        {/* Container set to show about 3 keys before scrolling */}
        <div style={{ maxHeight: '150px', overflowY: 'auto',padding: '8px',
      border: '1px solid grey',
      borderRadius: '4px',
      marginBottom: '16px' }}>
          <IonList>
            {Object.keys(customSubtitles).map((groupKey) => (
              <IonItem key={groupKey}>
                <IonCheckbox
                  slot="start"
                  checked={selectedSubtitleKeys.has(groupKey)}
                  onIonChange={(e) => handleSubtitleChange(groupKey, e.detail)}
                />
                <IonLabel>{groupKey}</IonLabel>
              </IonItem>
            ))}
          </IonList>
        </div>

        <h3>Chapter Groups <span style={{fontSize:"1rem"}}>(select to share)</span></h3>
        {/* Container set to show about 5 keys before scrolling */}
        <div style={{ maxHeight: '250px', overflowY: 'auto' , padding: '8px',
      border: '1px solid grey',
      borderRadius: '4px',
      marginBottom: '1px'}}>
          <IonList>
            {Object.keys(chapterGroups).map((groupKey) => (
              <IonItem key={groupKey}>
                <IonCheckbox
                  slot="start"
                  checked={selectedChapterKeys.has(groupKey)}
                  onIonChange={(e) => handleChapterChange(groupKey, e.detail)}
                />
                <IonLabel>{groupKey}</IonLabel>
              </IonItem>
            ))}
          </IonList>
        </div>
      </IonContent>
      <IonFooter class="ion-padding" >
     
          <IonButton expand="block" onClick={handleGenerateCode} disabled={loading}>
            {loading ? 'Generating...' : 'Generate Share Code'}
          </IonButton>
          {shareCode && (
            <IonItem class="ion-padding" button detail={false} onClick={(e) => onCopyShareCode(shareCode, e)}>
              <IonLabel>Share Code: {shareCode}</IonLabel>
              <IonIcon size="small" icon={copyOutline} slot="end" />
            </IonItem>
          )}
      
      </IonFooter>
    </IonModal>
  );
};
export default ShareCodeModal;

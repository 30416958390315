import { Storage } from "@ionic/storage"

export class BookmarkTilesService {
  static MAX_FAVORITES = 3000
  storage

  constructor() {
    this.storage = new Storage()
    this.storage.create()
  }

  //Due to differences to Favorites and History, use a toggle function to both add/remove
  //   async toggleBookmark(videoId, lang, index) {
  //     const key = `bookmark_${videoId}`;
  //     const videoBookmarks = await this.storage.get(key) || {};

  //     // If the lang exists in the bookmarks and contains the index
  //     if (videoBookmarks[lang] && videoBookmarks[lang].includes(index)) {
  //       // Remove the index from the bookmarks
  //       videoBookmarks[lang] = videoBookmarks[lang].filter(i => i !== index);

  //       // Remove the lang entirely if there are no more indices
  //       if (videoBookmarks[lang].length === 0) {
  //         delete videoBookmarks[lang];
  //       }

  //       await this.storage.set(key, videoBookmarks);
  //       return 0; // Return 0 for removal
  //     } else {
  //       // Otherwise, add the index to the bookmarks
  //       if (!videoBookmarks[lang]) {
  //         videoBookmarks[lang] = [];
  //       }
  //       videoBookmarks[lang].push(index);

  //       await this.storage.set(key, videoBookmarks);
  //       return 1; // Return 1 for insertion
  //     }
  //   }

  //Simple version that returns entire bookmarks for a lang, use approach above if this is too heavy
  async toggleBookmark(videoId, lang, bkmId) {
    const key = `bookmark_${videoId}`
    const videoBookmarks = (await this.storage.get(key)) || {}

    if (!videoBookmarks[lang]) {
      videoBookmarks[lang] = []
    }

    if (videoBookmarks[lang].includes(bkmId)) {
      videoBookmarks[lang] = videoBookmarks[lang].filter((i) => i !== bkmId)
    } else {
      if (
        Object.keys(videoBookmarks).length >= BookmarkTilesService.MAX_FAVORITES
      ) {
        throw new Error(
          "Maximum number of bookmarks reached, please clear old bookmarks before adding more"
        )
      }
      videoBookmarks[lang].push(bkmId)
    }

    // sort array in ascending order, since in bookmark only view it needs to be synced
    videoBookmarks[lang].sort((a, b) => a - b)
    await this.storage.set(key, videoBookmarks)
    return videoBookmarks[lang] // Return entire bookmarks for the specified language
  }

  // To save a tile we need to know the videoID, language of subtitle, and the index of the tile
  async addToBookmarks(videoId, lang, index) {
    const key = `bookmark_${videoId}`
    const videoBookmarks = (await this.storage.get(key)) || {}

    if (
      Object.keys(videoBookmarks).length >= BookmarkTilesService.MAX_FAVORITES
    ) {
      throw new Error(
        "Maximum number of favorite videos reached, please clear old favorites"
      )
    }

    if (videoBookmarks[lang]) {
      // Check if the index doesn't already exist in the array
      if (!videoBookmarks[lang].includes(index)) {
        videoBookmarks[lang].push(index)
      }
    } else {
      videoBookmarks[lang] = [index]
    }

    await this.storage.set(key, videoBookmarks)
  }

  // Removes all bookmarks for a specific videoId.
  async removeFromBookmarksAll(videoId) {
    const key = `bookmark_${videoId}`
    await this.storage.remove(key)
  }

  // Removes specific tile within a language for the video.
  // If index is set to -1, all tiles under the specified lang will be removed.
  async removeFromBookmarks(videoId, lang, index) {
    const key = `bookmark_${videoId}`
    const videoBookmarks = (await this.storage.get(key)) || {}

    if (index === -1) {
      delete videoBookmarks[lang]
    } else if (videoBookmarks[lang]) {
      videoBookmarks[lang] = videoBookmarks[lang].filter((i) => i !== index)
      if (videoBookmarks[lang].length === 0) {
        delete videoBookmarks[lang]
      }
    }

    await this.storage.set(key, videoBookmarks)
  }

  // This will return an array of all bookmarks for a video
  async getBookmarks(videoId, langCode) {
    const key = `bookmark_${videoId}`
    // const videoBookmarks = (await this.storage.get(key)) || {}
    // console.log("DEBUG: getBookmarks for videoId:", videoId, "langCode:", langCode, "returned:", videoBookmarks);

    // // Return only the specific language bookmarks for the given videoId
    // return videoBookmarks[langCode] || []
    console.log("DEBUG: About to call storage.get for key:", key, "for langCode:", langCode);
    try {
      // const rawBookmarks = await this.storage.get(key);
      const promise = this.storage.get(key);
      promise
        .then(result => {
          console.log("DEBUG: storage.get resolved with:", result, "for key:", key, "langCode:", langCode);
        })
        .catch(err => {
          console.error("DEBUG: storage.get rejected with:", err, "for key:", key, "langCode:", langCode);
        });
      const rawBookmarks = await promise;
      const videoBookmarks = rawBookmarks === null ? {} : rawBookmarks;

      console.log("DEBUG: GOT getBookmarks for videoId:", videoId, "langCode:", langCode, "returned:", videoBookmarks);
      return videoBookmarks[langCode] || [];
    } catch (error) {
      console.error("DEBUG: Error in getBookmarks for videoId:", videoId, "langCode:", langCode, error);
      throw error;
    }
  }

  // To update specific video's bookmarks with updatedData.
  async updateBookmark(videoId, updatedData) {
    const key = `bookmark_${videoId}`
    const videoBookmarks = (await this.storage.get(key)) || {}
    const mergedData = { ...videoBookmarks, ...updatedData }
    await this.storage.set(key, mergedData)
  }
}

const bookmarkTilesService = new BookmarkTilesService()
export default bookmarkTilesService
